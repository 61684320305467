
'use strict';

var _win = $(window);

// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
var keys = {37: 1, 38: 1, 39: 1, 40: 1};


$(_win).on('load',function() {
    const players = Plyr.setup('.js-player'); 

    $('.box-video').each(function(e){
        $(this).on('click','.video-overlay .cta-btn',  function(){
            
            players[e].play();
           
            $('.box-video#component-1').addClass('isPlaying');
            $('#component-1 video').trigger('play');
            $('html, body').animate({ scrollTop: $('#component-1 video').offset().top - 60}, 'slow');
        });
        players[e].on('stop', function(){
            
            $('.box-video#component-1').removeClass('isPlaying'); 
        })
        players[e].on('pause', function(){
           
            $('.box-video#component-1').removeClass('isPlaying'); 
        })

        $('#component-1').on('click', 'video',  function(){
            
            $('.box-video#component-1').removeClass('isPlaying');   
            
           
        });
    })
});
